
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'BetaSignup',
  data() {
    return {
      isSubmitted: false,
      email: ''
    }
  },
  methods: {
    handleSubmit() {
      axios
        .post('https://inciteful.app.n8n.cloud/webhook/academic-beta-signup', {
          email: this.email
        })

      this.isSubmitted = true
    }
  }
})
