
import { Author, PaperID } from '@/types/incitefulTypes'
import { defineComponent, PropType } from 'vue'
import AuthorComp from './Author.vue'

export default defineComponent({
  components: { AuthorComp },
  name: 'Authors',
  props: {
    showAffiliation: { type: Boolean, default: false },
    authors: {} as PropType<Author[]>,
    separator: { type: String, default: ', ' },
    showAll: { type: Boolean, default: false },
    authorClass: {} as PropType<string[]>,
    ids: {} as PropType<PaperID[]>
  },
  computed: {
    sortedAuthors(): Author[] {
      if (this.authors) {
        const sortAuth = [...this.authors]
        sortAuth.sort((a, b) => a.sequence - b.sequence)
        return sortAuth
      } else {
        return []
      }
    }
  },
  methods: {
    useSeperator(index: number) {
      return (
        this.sortedAuthors.length > 1 && index !== this.sortedAuthors.length - 1
      )
    }
  }
})
