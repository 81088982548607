
import { defineComponent } from 'vue'
// @ is an alias to /src
import BetaSignup from '@/components/BetaSignup.vue'
import GraphSearch from '@/components/GraphSearch.vue'
import ConnectorSearch from '@/components/ConnectorSearch.vue'
import navigation from '../navigation'
import { PaperID } from '@/types/incitefulTypes'
import {
  DocumentReportIcon,
  CubeTransparentIcon,
  GlobeAltIcon
} from '@heroicons/vue/outline'

export default defineComponent({
  name: 'Home',
  components: {
    BetaSignup,
    GraphSearch,
    ConnectorSearch,
    DocumentReportIcon,
    CubeTransparentIcon,
    GlobeAltIcon
  },
  methods: {
    addLitReviewPapers(ids: PaperID[]) {
      if (ids.length === 1) {
        this.$router.push({ path: navigation.getPaperUrl(ids[0]) })
      } else {
        this.$router.push({ name: 'LitReview', query: { ids } })
      }
    },
    goToSearch(query: string) {
      this.$router.push({ name: 'Search', query: { q: query } })
    }
  }
})
