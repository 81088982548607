
import { defineComponent } from 'vue'
import api from '../utils/incitefulApi'
import numeral from 'numeral'
import { Paper, PaperID } from '@/types/incitefulTypes'
import Authors from './Authors.vue'
import { searchOpenAlex } from '../utils/openalexApi'

export default defineComponent({
  name: 'Autosuggest',
  components: {
    Authors
  },
  props: {
    clearOnSelect: {
      type: Boolean,
      default: false
    },
    defaultQuery: {
      type: String,
      default: null
    },
    defaultPaperId: {
      type: String,
      default: undefined
    }
  },
  emits: ['selected', 'searched', 'selectedPaper'],
  data() {
    return {
      query: '',
      showResults: true,
      results: undefined as Paper[] | undefined,
      timeout: null as number | null,
      selected: null as PaperID | null,
      highlighted: null as number | null,
      debounceMilliseconds: 200,
      selectIsValid: true,
      isFocused: false
    }
  },
  created(): void {
    this.query = this.defaultQuery
    if (this.defaultPaperId) {
      api.getPaper(this.defaultPaperId).then(data => {
        if (data !== undefined) {
          this.query = this.getPaperValue(data)
        }
      })
    }
  },
  computed: {
    shouldShowResults(): boolean {
      return (
        this.showResults &&
        this.isFocused &&
        this.results !== undefined &&
        this.results.length > 0
      )
    }
  },
  watch: {
    query(newVal) {
      const query = newVal
      this.selectIsValid = true
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = window.setTimeout(() => {
        this.selected = null
        searchOpenAlex(query).then(papers => {
          if (papers && papers.length > 0) {
            this.results = papers.slice(0, 10)
            this.displayResults()
          }
        })
      }, this.debounceMilliseconds)
    }
  },
  methods: {
    registerKeypress(direction: 'up' | 'down') {
      if (this.results) {
        if (this.highlighted === null) {
          this.highlighted = 0
        } else if (direction === 'down') {
          this.highlighted = this.highlighted + 1
        } else {
          this.highlighted = this.highlighted - 1
        }
        if (this.highlighted < 0) {
          this.highlighted = this.results.length
        } else {
          this.highlighted = this.highlighted % this.results.length
        }
      }
    },
    hideResults() {
      this.showResults = false
      this.highlighted = null
    },
    displayResults() {
      this.showResults = true
    },
    getValue() {
      if (this.getSelectedId()) return this.getSelectedId()
      else return this.query
    },
    getSelectedId(): string | undefined {
      if (
        this.results &&
        this.highlighted !== null &&
        this.results[this.highlighted] !== undefined
      ) {
        return this.results[this.highlighted].id
      }
    },
    format(val: number) {
      return numeral(val).format('0,0.[000000]')
    },
    getPaperValue(paper: Paper): string {
      return `${paper.title} (${paper.id})`
    },
    sendSelect(index: number | null) {
      return () => {
        if (this.results && index !== null) {
          const paper = this.results[index]
          if (paper) {
            this.showResults = false
            // @ts-ignore
            this.$refs.searchBox.blur()
            this.$emit('selected', [paper.id])
            this.query = `${paper.title} (${paper.id})`
          }
        }
      }
    },
    sendSearched() {
      if (this.highlighted !== null && this.highlighted >= 0) {
        this.sendSelect(this.highlighted)()
      } else if (this.query) {
        this.$emit('searched', this.query)
        this.showResults = false
        // @ts-ignore
        this.$refs.searchBox.blur()
      }
    }
  }
})
