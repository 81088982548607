
import { defineComponent } from 'vue'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Footer
  },
})
