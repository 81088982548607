import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthorComp = _resolveComponent("AuthorComp")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedAuthors, (author, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, [
        (_ctx.showAll || index == 0 || index == _ctx.sortedAuthors.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_AuthorComp, {
                author: author,
                ids: _ctx.ids,
                showAffiliation: _ctx.showAffiliation,
                authorClass: _ctx.authorClass
              }, null, 8, ["author", "ids", "showAffiliation", "authorClass"]),
              (_ctx.useSeperator(index))
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.separator), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (
          !_ctx.showAll &&
            index != 0 &&
            index != _ctx.sortedAuthors.length - 1 &&
            index == 1
        )
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ... "))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}