
import { defineComponent } from 'vue'
import Autosuggest from './Autosuggest.vue'
import { SearchIcon } from '@heroicons/vue/outline'

export default defineComponent({
  name: 'ConnectorSearch',
  components: {
    Autosuggest,
    SearchIcon
  },
  props: {
    showImport: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: false
    },
    fromId: {
      type: Number,
      default: undefined
    },
    toId: {
      type: Number,
      default: undefined
    }
  },
  methods: {
    searchClick() {
      // @ts-ignore
      const toParam = this.$refs.toAutosuggest.getValue()
      // @ts-ignore
      const fromParam = this.$refs.fromAutosuggest.getValue()
      this.$router.push({
        name: 'LitConnector',
        query: { to: toParam, from: fromParam }
      })
    }
  }
})
