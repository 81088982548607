
import { Author, PaperID } from '@/types/incitefulTypes'
import { AuthorModalOptions } from '@/types/modalTypes'
import { showModalHelper } from '@/utils/emitHelpers'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Author',
  props: {
    showAffiliation: { type: Boolean, default: false },
    author: {} as PropType<Author>,
    ids: {} as PropType<Array<PaperID>>,
    authorClass: {} as PropType<string[]>
  },
  methods: {
    showModal(author: Author | undefined) {
      if (author) {
        const options: AuthorModalOptions = {
          author,
          contextIds: this.ids
        }
        showModalHelper(options)
      }
    }
  }
})
