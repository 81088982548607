import { withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 0,
  class: "origin-top-right absolute z-50 w-full rounded-b border rounded-tr-none rounded-tl-none border-gray-300 rounded-md bg-white p-0 m-0 overflow-y-scroll max-h-96;"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Authors = _resolveComponent("Authors")!
  const _directive_touch = _resolveDirective("touch")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "text-gray-800 relative",
    onKeydown: [
      _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.hideResults()), ["esc"])),
      _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.sendSelect(null)), ["enter"]))
    ]
  }, [
    _createElementVNode("input", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayResults())),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.registerKeypress('up')), ["up"])),
        _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.registerKeypress('down')), ["down"])),
        _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.sendSearched()), ["enter"]))
      ],
      onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isFocused = true)),
      class: "w-full px-4 py-2 border border-gray-300 rounded-md leading-5 bg-white transition duration-150 ease-in-out focus:outline-none focus:border-blue-300",
      ref: "searchBox",
      type: "text",
      placeholder: "Paper title, DOI, PubMed URL, or arXiv URL",
      value: _ctx.query,
      onInput: _cache[5] || (_cache[5] = e => (_ctx.query = e.target ? (e.target as HTMLInputElement).value : ''))
    }, null, 40, _hoisted_1),
    (_ctx.shouldShowResults)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", {
            class: "list-none p-0 m-0",
            onKeydown: [
              _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.registerKeypress('up')), ["left"])),
              _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.registerKeypress('up')), ["up"])),
              _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.registerKeypress('down')), ["right"])),
              _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.registerKeypress('down')), ["down"]))
            ]
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["cursor-pointer p-2 border-t border-gray-200 hover:bg-gray-200", { 'bg-gray-200': _ctx.highlighted === index }]),
                key: index,
                onClick: ($event: any) => (_ctx.sendSelect(index))
              }, [
                _createElementVNode("div", {
                  class: "pb-1 text-sm",
                  innerHTML: result.title
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Authors, {
                    authors: result.author
                  }, null, 8, ["authors"]),
                  _createTextVNode(" (" + _toDisplayString(result.published_year) + ") - " + _toDisplayString(_ctx.format(result.num_cited_by)) + " citations ", 1)
                ])
              ], 10, _hoisted_3)), [
                [_directive_touch, _ctx.sendSelect(index), "tap"]
              ])
            }), 128))
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ], 32)), [
    [_directive_click_away, _ctx.hideResults]
  ])
}