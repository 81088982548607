
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
  setup () {
    return {
      year: new Date().getFullYear()
    }
  }
})
