
import { defineComponent, ref } from 'vue'
import GraphSearch from '@/components/GraphSearch.vue'
import navigation from '../../navigation'
import { PaperID } from '@/types/incitefulTypes'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

export default defineComponent({
  name: 'Header',
  components: {
    GraphSearch,
    MenuIcon,
    XIcon
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const userStore = useUserStore()
    let mobileMenuExpanded = ref(false)

    const toggleMobileMenu = () => {
      mobileMenuExpanded.value = !mobileMenuExpanded.value
    }
    const goToPaper = (id: PaperID) => {
      router.push({ path: navigation.getPaperUrl(id) })
    }

    const goToSearch = (query: string) => {
      if (query && route.query.q !== query) {
        router.push({ name: 'Search', query: { q: query } })
      }
    }

    const signOut = () => {
      throw new Error('Not implemented')
    }

    return {
      mobileMenuExpanded,
      toggleMobileMenu,
      goToPaper,
      user: userStore,
      signOut,
      goToSearch
    }
  }
})
