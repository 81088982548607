import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal(_ctx.author))),
    title: _ctx.author?.institution?.name,
    class: _normalizeClass(['hover:underline'].concat(_ctx.authorClass ? _ctx.authorClass : []))
  }, [
    _createTextVNode(_toDisplayString(_ctx.author?.name) + " ", 1),
    (_ctx.showAffiliation)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.author?.institution?.name) + ")", 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}